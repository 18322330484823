import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [createPersistedState()],
  state: {
    products: [],
    categories: [],
    itemsInCart: [],
  },
  mutations: {
    addProduct (state, payload){
      
      state.products.push(payload)
    },
    addCategory (state, payload) {
      state.categories.push(payload)
    },
    addItemToCart(state, payload){
      let ignore = false
      state.itemsInCart.forEach(item => {
        if(item.id === payload.id){
          // // Create seperate cart item if product has a seperate varient name
          // if(item.varientName !== payload.varientName){
            

          // } else {
          //   // If its the same varient and product, increase the quantity of the exisitng cart item
          //   item.quantity = parseInt(item.quantity) + parseInt(payload.quantity)
          //   ignore = true
          // }
          
          if(item.varientName === payload.varientName){
            item.quantity = parseInt(item.quantity) + parseInt(payload.quantity)
            ignore = true
          }
        }
      })

      if(!ignore){
        state.itemsInCart.push(payload)
      }
    },
    clearProducts(state) {
      state.products = []
    },
    clearCategories(state){
      state.categories = []
    },
    removeItem(state, payload){
      state.itemsInCart = state.itemsInCart.filter((item) => item.deleteId !== payload)
    },
    clearCart(state) {
      state.itemsInCart = []
    }
  },
  actions: {
  },
  getters: {
    getProducts: state => {
      return state.products
    },
    getCategories: state => {
      return state.categories
    },
    getCartItems: state => {
      return state.itemsInCart
    }
  }
})
