<template> 
<div class='scroll' data-simplebar data-simplebar-auto-hide="false"> 
    <div class="scrolling-wrapper-flexbox mt-lg-4 mt-sm-6 mt-xs-10" >
      <v-icon class='icon mdi-48px d-flex d-sm-none'> mdi-gesture-swipe-left </v-icon>
      <ProductScrollCard v-for='item in items' :key='item.id' :imageLink='item.imageLink' :productTitle="item.productTitle" :productDescription="item.productDescription" :productLink="item.link"/>
    </div>
  </div>
</template>

<script>
import ProductScrollCard from '../components/ProductScrollCard.vue'

export default{
    name: 'HorizontalScroll',
    components: {
        ProductScrollCard,
    },
    data () {
        return {
            items: [
                {id: 1, 
                productTitle: 'Electric Beds', 
                productDescription:"If There's one thing that we know, it's good sleep. An adjustable bed takes your Netflix movie night, latenight reading and breakfast in bed to a whole new level of comfort. ",
                imageLink: "https://images-global-icon.s3.us-east-2.amazonaws.com/elect+bed.png",
                link: "bedroom/all"},
                

                {id: 2, 
                productTitle: 'Laurel Mattresses ', 
                productDescription:'Enjoy the simple pleasure of a great night sleeps at the pefect price point with any of our Laurel Matresses. ', 
                imageLink: 'https://images-global-icon.s3.us-east-2.amazonaws.com/IMG-20210930-WA0025.jpg',
                link: "bedroom/all"},

                {id: 3, 
                productTitle: 'Rug Collections', 
                productDescription:'Explore and discover our unique rug collections, curated for style, comfort and everyday living. ', 
                imageLink: 'https://images-global-icon.s3.us-east-2.amazonaws.com/Screen+Shot+2016-09-02+at+12.17.27+AM.png',
                link: "accents/all"},

                {id: 4, 
                productTitle: 'Kmotion Sectional', 
                productDescription:'Consider this charming reclining sectional collection for all your decorating needs. The brilliant white tone will make pairing this item with your room decor breezy and fun.', 
                imageLink: 'https://images-global-icon.s3.us-east-2.amazonaws.com/338+WHITE+SECT.jpg',
                link: "products/km-338"},

                {id: 5, 
                productTitle: 'Lucky Sectional', 
                productDescription:"Allow your lounging experience to begin and end here on the Lucky sectional. Delightfully comfortable and able to bring softness and warmth to a modern space, this sectional is a room-defining element for any size space.", 
                imageLink: 'https://images-global-icon.s3.us-east-2.amazonaws.com/South+Beach+2+RED+(+123035)+1+(1)+(3).png',
                link: "products/paris-bedroom-set"},
            ]
        }
    }


}
</script>

<style scoped>
  .scrolling-wrapper-flexbox {
    height: 550px;
    width: 90vw;
    display: flex;
    flex-wrap: auto;
  }
  /* Create custom widths % for different screen sizes to maintain aspect ratio */
  .card {
    flex: 0 0 auto;
    height: 90%;
    width: 40%;
    margin-left: 40px;
  }

  .scroll {
    width: 90vw;
  }

  .scroll {
    position: relative;
  }
  .simplebar-scrollbar:before{
    background-color:red;
  }
  .icon {
    max-height:75%;
    
  }

</style>

