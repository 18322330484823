<template>
    <div class='scroll' data-simplebar data-simplebar-auto-hide="false"> 
        <div class="scrolling-wrapper-flexbox mt-lg-4 mt-sm-6 mt-xs-10" >
            <div v-for='category in categories.filter(c => c.categoryRoom === room)' :key='category.id' class='pa-2 pl-3 pr-3'>
                <v-card class='card' elevation="5" @click='ChangeActiveCategory(category.categoryName)'> 
                    <div class='image-wrapper'> 
                            <v-img
                            class='image'
                            contain
                            height= '170px'
                            width='325px'
                            :src="'http:' + category.categoryURL"
                            >
                        </v-img>                
                    </div>    
                    <div class='card-header'> 
                        <span class='centered'> {{category.categoryName}}</span>
                    </div>
                </v-card>  
            </div>            
        </div>
    </div>
</template>

<script>
export default{
    name: 'CategoryHorizontalScroll',
    async mounted () {
        this.categories = this.$store.getters.getCategories
    },
    data () {
        return {
            categories: [],
        }
    },
    props: ['room'],
    methods: {
        ChangeActiveCategory(categoryName) {
            this.$emit('change-category', categoryName)
        },
    }
}
</script>

<style>
    .scroll {
        width: 90vw;
    }

    .scroll {
        position: relative;
    }
    .simplebar-scrollbar:before{
        background-color:black;
    }
    .scrolling-wrapper-flexbox {
        height: 275px;
        width: 90vw;
        display: flex;
        flex-wrap: auto;
        margin-bottom: 2vh;
    }

    .card-header {
        background-color: #68788C; 
        height:7vh;
        display: flex;
        justify-content: center;
        text-align: center;
        align-items: center;
    }

    .centered {
        font-weight: 400;
        font-size: 1.5rem;
        letter-spacing: 0.04cm;
    }
</style>