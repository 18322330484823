<template> 
  <v-card 
  max-width="425"
  min-width="350"
  >
      <v-img
        height="250"
        :src="imageLink"
        fill
      ></v-img>
       <div class="title">
        <v-card-title>{{productTitle}}</v-card-title>
      </div>
      <div class='body'> <v-card-text>
        <div class='paragraph-body'> {{ productDescription }}</div>
      </v-card-text>
      </div>
      <div class="button-div d-flex justify-center">
        <v-btn 
        class="text-button card-button"
        outlined
        color="black"
        :to='productLink'
        >
          Explore {{productTitle}}
        </v-btn>
      </div>
  </v-card>
</template> 


<script>

export default {
    name: 'ProductScrollCard',
    props: ['productTitle', 'productDescription', 'imageLink', 'productLink'],
    data () {
        return {
            ImageLink: this.imageLink
        }
    }

}
</script>

<style scoped>
  .v-card:first-of-type {
    margin-left: 20px;
  }

  .v-card {
    max-height: 515px;
    margin-left: 75px;
  }
 
    /* All items are treated as being the first of type. Possible solution: Pass a custom class from horizontalscroll so that it only affects the first element that has that class. 
  .card-button:first-of-type {
   
  }

  */
  .title {
    height: 10%;
  }
  .body {
    height: 30%;
  }
  .button-div {
    height: 10%;
  }
</style>
