<template>
  <v-app>
    <v-main>
        <Menu> </Menu>  
        <router-view/>
    </v-main>
  </v-app>
</template>

<script>
import Menu from './components/Menu.vue'
import store from "./store"
import axios from "axios"
export default {
    name: 'App',
     components: {
        Menu,
    },

    data () {
        return {
            products: [],
            productsData: [],
            varients: [],
            jsonData: [],
            jsonDataCategories: [],
            pictures: [],
            object: {},    
        }
    },

    async created () {
        await this.getProductData(0)
        this.getProducts()
        await this.getCategoryData()
        this.getCategories()

        document.title = 'Florida Furniture Distributors'
    },
    
    methods: {
        async getProductData(skip) {
                
            await axios.get("https://cdn.contentful.com/spaces/udingn1xf8ky/environments/master/entries?content_type=product&skip=" + skip + "&access_token=VDeqNixLK4Ghj2Ebl8bW7f4uE9rivXNyq6irxrOyM5E")
                .then(response => (this.jsonData = response.data))
            
            if (parseInt(this.jsonData.total) > 100){
                
                let total = this.jsonData.total.toString()
                let maxSkip = parseInt(total.charAt(0)) * 100
                var newResponse = []
                var checkResponse = []
                
                for(let i = skip; i <= maxSkip; i = i + 100){
                    var checkResponse = []
                    await axios.get("https://cdn.contentful.com/spaces/udingn1xf8ky/environments/master/entries?content_type=product&skip=" + i + "&access_token=VDeqNixLK4Ghj2Ebl8bW7f4uE9rivXNyq6irxrOyM5E")
                        .then((response) => (checkResponse.push(response.data)))
                    
                    if(checkResponse[0].total !== 0){
                        newResponse.push(checkResponse)
                    }
                }
                this.jsonData = newResponse
            }
        },

        async getCategoryData() {
            await axios.get("https://cdn.contentful.com/spaces/udingn1xf8ky/environments/master/entries?content_type=categories&access_token=VDeqNixLK4Ghj2Ebl8bW7f4uE9rivXNyq6irxrOyM5E")
            .then(response => (this.jsonDataCategories = response.data))
        },

        getProducts() {
            this.$store.commit('clearProducts')
            // let data = []
            // data.push(this.jsonData)

             this.jsonData.forEach(jsonData => {    
                this.productsData = jsonData[0].items
                this.pictures = jsonData[0].includes.Asset
                this.varients = jsonData[0].includes.Entry
  
                this.productsData.forEach(data => {
                    var product = {
                        id: data.sys.id,
                        name: data.fields.productname,
                        category: '',
                        room: '',
                        price: 0,
                        msrp: 0.00,
                        description: data.fields.description,
                        collectionIDS: [],
                        mainImage: '',
                        imageURLS: [],
                        varients: [],
                        weight: data.fields.weight,
                        length: data.fields.length,
                        width: data.fields.width,
                        height: data.fields.height,
                        cbm: data.fields.cbm,
                        productSlug: data.fields.productSlug,
                        stock: data.fields.stock,
                        restockDate: null,
                    }
                    // Get MSRP
                    product.price = data.fields.price
                    let msrp = parseFloat(product.price) * 2.50
                    product.msrp = (Math.round(msrp * 100) / 100).toFixed(2)
                    

                    // Fetches all the Image URls and adds them to the imageURLS array.
                    var imageURLID = []
                    var hasImages = true  
                    try {
                        data.fields.images.forEach(pictures => {
                        imageURLID.push(
                            pictures.sys.id
                        )
                    })
                    }catch(e){ hasImages = false}

                    try {
                        product.restockDate = data.fields.restockDate
                        let dates = data.fields.restockDate.split("-")
                        let datesStr = dates[1]+"/"+dates[2]+"/"+dates[0]
                        product.restockDate = datesStr
                    } catch(e){}


                    // Get the Main image ID
                    let mainImageURl = ''
                    try {
                        mainImageURl = data.fields.mainImage.sys.id
                    } catch(e) {}

                    // Go through all the pictures and get the main image and additional images URLS
                    this.pictures.forEach(data => {
                        try{ 
                            if(data.sys.id === mainImageURl){
                                product.mainImage = data.fields.file.url
                            }
                            else {
                                imageURLID.forEach(urlID => {
                                    if(hasImages && data.sys.id == urlID){
                                        product.imageURLS.push(data.fields.file.url)
                                    }
                                })
                            }   
                        } catch (e) {}
                    })
                    
                    let categoryID = data.fields.category.sys.id
                    var varientID = []
                    let collectionID = null
                    try{
                        collectionID = data.fields.collection[0].sys.id
                    }catch(e){collectionID = null}
                    
                    
                    
                // Checks if this product has varients 
                    // Get Varient ID
                    console.log(data)
                    try {
                        data.fields.varients.forEach(varient => {
                            varientID.push(varient.sys.id)
                        })
                    } catch(e){
                        
                    }


                    // Find Varient By ID 
                    try {
                        this.varients.forEach(varientdata => {
                            varientID.forEach(id => {
                                if(varientdata.sys.id === id){
                                    product.varients.push(varientdata.fields)
                                }
                            })
                            // Get the Category and Room ID for the Product
                            if(varientdata.sys.id === categoryID){
                                product.category = varientdata.fields.categoryName
                                product.room = varientdata.fields.categoryRoom
                            }
                            else if(!!collectionID){
                                if(varientdata.sys.id === collectionID){
                                    varientdata.fields.products.forEach(p => {
                                        // this.collectionIDS.push(p.sys.id)
                                        // console.log(p.sys.id)
                                        product.collectionIDS.push(p.sys.id)
                                    })
                                }
                                
                            }
                            


                        })
                    } catch(e){}

                    // Push item into products **Converto TO VUEX** 
                    // this.products.push(product)
                    this.$store.commit('addProduct', product)
                })
            })
        },

        getCategories() {
            this.$store.commit('clearCategories')
            var categoryData = this.jsonDataCategories.items
            var categoryPictures = this.jsonDataCategories.includes.Asset
                
            categoryData.forEach(data => {
                var categoryTemplate = {
                    categoryID: data.sys.id,
                    categoryName: data.fields.categoryName,
                    categoryRoom: data.fields.categoryRoom,
                    categoryURL: "",
                }
         
                try{
                    let urlID = data.fields.categoryImage.sys.id
                    
                    categoryPictures.forEach(data => {
                        if(data.sys.id === urlID){
                            categoryTemplate.categoryURL = data.fields.file.url       
                        }
                    })

                } catch(e){
                   
                }
                
                this.$store.commit('addCategory', categoryTemplate)
            })
        },
    }
};
</script>

<style>
    @font-face {
        font-family: "Bauer";
        src: local("Bauer"),
        url(/assets/fonts/Bauer-Bodoni-Regular.otf) 
    }

</style>