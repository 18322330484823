<template>
  <div class='basic-grid-grid'>
      <div
      v-for="category in categories.filter((c) => c.categoryRoom === room)"
      :key="category.id" class='pa-1'>
        <v-card class='card-grid' elevation="3" @click='ChangeActiveCategory(category.categoryName)'> 
                    <div class='image-wrapper-grid'> 
                            <v-img
                            class='image'
                            fill
                            height= '175px'
                            width='45vw'
                            :src="'http:' + category.categoryURL"
                            >
                        </v-img>                
                    </div>    
                    <div class='card-header-grid'> 
                        <span class='centered-grid'> {{category.categoryName}}</span>
                    </div>
                </v-card> 
      
      </div>
  </div>
</template>

<script>
export default{
    name: 'CategoryGrid',
    mounted () {
        this.categories = this.$store.getters.getCategories
    },
    data () {
        return {
            categories: [],
        }
    },
    props: ['room'],
    methods: {
        ChangeActiveCategory(categoryName) {
            this.$emit('change-category', categoryName)
        }
    }

}
</script>

<style scoped> 
    .basic-grid-grid{
        display:grid;
        gap: 1rem;
        grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
        
    }

    .centered {
        font-weight: 400;
        font-size: 1.5rem;
        letter-spacing: 0.04cm;
    }

    .card-header {
        background-color: #68788C; 
        height:5vh;
        display: flex;
        justify-content: center;
        text-align: center;
        align-items: center;
    }
    .card-header-grid {
        height: 7vh;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #68788C;
        padding: 2px 2px 2px 2px;
        text-align: center;

    }
    .centered-grid {
        font-weight: 500;
        font-size: 1rem;
        letter-spacing: 0.04cm;
    }
    
</style>