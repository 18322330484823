<template>
    <div>
		<div class="column">
			<div class='image-column'>
                <img id=featured :src="'https:' + mainImage">
            </div>

			<div id="slide-wrapper" >
				<img id="slideLeft" class="arrow" src="images/arrow-left.png">
				<div id="slider">
                    <img class="thumbnail" :src="'https:' + mainImage">
                    <div v-for='imageURL in images' :key='imageURL.id'> 
                        <img class="thumbnail" :src="'https:' + imageURL">
                    </div>
				</div>
				<img id="slideRight" class="arrow" src="images/arrow-right.png">
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'ImageSlider',
    async mounted () {
        await this.sleep(100)
        this.imageSlider()
    },
    methods: {
        imageSlider() {
            let thumbnails = document.getElementsByClassName('thumbnail')

		let activeImages = document.getElementsByClassName('active')

		for (var i=0; i < thumbnails.length; i++){

			thumbnails[i].addEventListener('mouseover', function(){
				console.log(activeImages)
				
				if (activeImages.length > 0){
					activeImages[0].classList.remove('active')
				}
				

				this.classList.add('active')
				document.getElementById('featured').src = this.src
			})
		}


		let buttonRight = document.getElementById('slideRight');
		let buttonLeft = document.getElementById('slideLeft');

		buttonLeft.addEventListener('click', function(){
			document.getElementById('slider').scrollLeft -= 180
		})

		buttonRight.addEventListener('click', function(){
			document.getElementById('slider').scrollLeft += 180
		})
        },
        sleep(ms){
                return new Promise(resolve => setTimeout(resolve, ms))
            },
    },

    props:['mainImage', 'images']

}

</script>

<style scoped>

.image-column {
    height: 40vh;
    width: 40vw;
}
#featured{
	max-width: 100%;
    height: auto;
	object-fit: cover;
	cursor: pointer;
	border: 2px solid black;

}

.thumbnail{
	object-fit: cover;
	max-width: 180px;
	max-height: 100px;
	cursor: pointer;
	opacity: 0.5;
	margin: 5px;
	border: 2px solid black;

}

.thumbnail:hover{
	opacity:1;
}

.active{
	opacity: 1;
}

#slide-wrapper{
	max-width: 500px;
	display: flex;
	min-height: 100px;
	align-items: center;
}

#slider{
	width: 440px;
	display: flex;
	flex-wrap: nowrap;
	overflow-x: auto;

}

#slider::-webkit-scrollbar {
		width: 8px;

}

#slider::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);

}
 
#slider::-webkit-scrollbar-thumb {
  background-color: #dede2e;
  outline: 1px solid slategrey;
   border-radius: 100px;

}

#slider::-webkit-scrollbar-thumb:hover{
    background-color: #18b5ce;
}

.arrow{
	width: 30px;
	height: 30px;
	cursor: pointer;
	transition: .3s;
}

.arrow:hover{
	opacity: .5;
	width: 35px;
	height: 35px;
}
</style>