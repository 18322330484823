<template> 
    <v-carousel
        :continuous="true"
        :show-arrows="true"
        hide-delimiter-background
        cycle
        hide-delimiters
    
        delimiter-icon="mdi-minus"
        class='carousel'
        height="carouselHeight"
        min-height='500px'
    >
        <v-carousel-item class='coursel-item'>
            <div class='image-wrapper d-flex flex-column flex-lg-row justify-space-between ' > 
                 <v-img 
                    src='https://images-global-icon.s3.us-east-2.amazonaws.com/Gold+Coast+grey+(254106)+.JPG' 
                    :max-width='imageWidth'
                    :height='imageHeight'
                    min-height='300px'
                    fill-width
                    href='/'>
                </v-img>
                <div class='image-side green-background d-flex align-center flex-column'> 
                    <div class='header d-flex'> 
                       <span class='text-lg-h3 text-xl-h2 text-md-h4 text-sm-h5 text-h6 pt-lg-6 pt-xl-6 '>  Check Out Our Newest Living Room Items </span> 
                    </div>
                    <div class="body text-lg-subtitle-1 text-subtitle-2 pt-lg-11 pt-xl-10 pt-md-4 pt-sm-3 mt-4"> Browse and shop the latest Living Room arrivals at Florida Furniture Distributors and our newest discover products and collections. </div>
                    <div class="button-div-flex  pb-lg-16 pb-md-9 pb-sm-7 pb-10 ">
                        <div><v-btn 
                            class="text-button card-button text-xs-caption text-lg-button d-none d-sm-flex"
                            outlined
                            color="black"
                            to='/livingroom/Living%20Room%20%20Sets'
                            >
                            Explore Our Living Room Collections
                        </v-btn>
                        <v-btn 
                            class="text-button card-button text-xs-caption text-lg-button d-sm-none d-flex text-center d-flex text-center"
                            outlined
                            color="black"
                            to='/livingroom/Living%20Room%20%20Sets' 
                            >
                            Explore Our <br>Living Room Collections
                        </v-btn>
                        
                        </div>
                    </div>
                </div>        
            </div>
        </v-carousel-item>
        
        <v-carousel-item class='coursel-item'>
            <div class='image-wrapper d-flex flex-column flex-lg-row justify-space-between' > 
                <v-img 
                src='https://images-global-icon.s3.us-east-2.amazonaws.com/IMG-20210930-WA0006.jpg' 
                :max-width='imageWidth'
                :height='imageHeight'
                min-height='300px'
                fill-width
                href='/'>
                </v-img>   
                <div class='image-side purple-background d-flex align-center flex-column'> 
                    <div class='header d-flex'> 
                       <span class='text-lg-h3 text-xl-h2 text-md-h4 text-sm-h5 text-h6 pt-lg-6 pt-xl-6'>  Check Out Our Newest Items </span> 
                    </div>
                    <div class="body text-lg-subtitle-1 text-subtitle-2 pt-lg-11 pt-xl-10 pt-md-4 pt-sm-3 mt-4"> Browse and shop the latest arrivals at Florida Furniture Distributors and discover prododucts and collections for every room and style.</div>
                    <div class="button-div-flex  pb-lg-16 pb-md-9 pb-sm-7 pb-10 ">
                        <div><v-btn 
                            class="text-button card-button text-xs-caption text-lg-button d-none d-sm-flex"
                            outlined
                            color="black"
                            to='/diningroom/Dining%20Room%20%20Sets'
                            >
                            Explore Our Dining Room Collections
                        </v-btn>
                        <v-btn 
                            class="text-button card-button text-xs-caption text-lg-button d-sm-none d-flex text-center"
                            outlined
                            color="black"
                            to='/diningroom/Dining%20Room%20%20Sets'
                            >
                            Explore Our <br>Dining Room Collections
                        </v-btn>
                        
                        </div>
                    </div>
                </div>    
            </div>
        </v-carousel-item>

         <v-carousel-item class='coursel-item'>
            <div class='image-wrapper d-flex flex-column flex-lg-row justify-space-between' > 
                <v-img 
                src='https://images-global-icon.s3.us-east-2.amazonaws.com/photo-1616594039964-ae9021a400a0.jfif' 
                :max-width='imageWidth'
                :height='imageHeight'
                min-height='300px'
                fill-width
                href='/'>
                </v-img>   
                <div class='image-side dark-background d-flex align-center flex-column'> 
                    <div class='header d-flex'> 
                       <span class='text-lg-h3 text-xl-h2 text-md-h4 text-sm-h5 text-h6 pt-lg-6 pt-xl-6 text-color'>  Shop Our Favourite Beds </span> 
                    </div>
                    <div class="body text-lg-subtitle-1 text-subtitle-2 pt-lg-11 pt-xl-10 pt-md-4 pt-sm-3 mt-4"><span class='text-color'>Browse and shop our favourite bedroom collections that are not only supremely crafted, but make your bedroom feel like your special spot.</span></div>
                    <div class="button-div-flex  pb-lg-16 pb-md-9 pb-sm-7 pb-10 ">
                        <div><v-btn 
                            class="text-button card-button text-xs-caption text-lg-button d-none d-sm-flex"
                            outlined
                            color="black"
                            to='/bedroom/Bedroom%20Sets'
                            >
                            Explore Our Bedroom Collections
                        </v-btn>
                        <v-btn 
                            class="text-button card-button text-xs-caption text-lg-button d-sm-none d-flex text-center"
                            outlined
                            color="black"
                            to='/bedroom/Bedroom%20%20Sets'
                            >
                            Explore Our <br>Bedroom Collections
                        </v-btn>
                        
                        </div>
                    </div>
                </div>    
            </div>
        </v-carousel-item>
    </v-carousel>
</template>

<script>
    export default {
        name: 'Carousel',
        computed: {
            imageWidth () {
                switch (this.$vuetify.breakpoint.name) {
                    case 'xs': return '100%'
                    case 'sm': return '100%'
                    case 'md': return '100%'
                    case 'lg': return '70%'
                    case 'xl': return '70%'
                }
            },
            carouselHeight () {
                switch (this.$vuetify.breakpoint.name) {
                    case 'xs': return "65vh"
                    case 'sm': return "65vh"
                    case 'md': return "60vh"
                    case 'lg': return "70vh"
                    case 'xl': return '70vh'
                }
            },
            imageHeight () {
                switch (this.$vuetify.breakpoint.name) {
                    case 'xs': return "30vh"
                    case 'sm': return "30vh"
                    case 'md': return "50vh"
                    case 'lg': return "70vh"
                    case 'xl': return '70vh'
                }
            },
                sideHeight()  {
                    switch (this.$vuetify.breakpoint.name) {
                    case 'xs': return "40vh"
                    case 'sm': return "50vh"
                    case 'md': return "60vh"
                    case 'lg': return "height=10vh"
                    case 'xl': return '70vh'
                    }
                }
            }
        }
    
</script>

<style scoped> 
   .carousel {
        width: 90vw;
        margin-top: 3vh;
        
    }
   
   .image-wrapper{
        width: 100%;
        height: 100%;
    }

    .image-side {
        max-width: 100%;
        min-width: 30%;
        max-height: 1454px;
        min-height: 150px;
       
    }
    .purple-background{
        background-color: #82808C;
    }
    .green-background {
        background-color: #8C8C77;
    }

    .dark-background {
        background-color: #D6D6CE;
    }
    .content-div-1 {
        height: 15%;
        width: 90%;
    
    }

    .content-div-2 {
        
        width: 90%;
        height:65%;
        position: relative
    }

    .button-flex {
        position:absolute;
        bottom:0;
        margin-right: auto;
        margin-left:auto;
    }
    .text-color {}

    .header {
        height:25%;
        width: 90%;
        text-align: center;
        justify-content: center;
        margin-top: 1vh;
    }
    .body {
        width: 90%;
        height: 60%;
        text-align: center;
    }

    .button-div-flex {
        width: 90%;
        height: 15%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

    }
</style>