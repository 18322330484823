<template> 
    


</template>

<script> 
    export default{
        name: 'LandingPage',
    }
</script>

<style scoped>

</style>