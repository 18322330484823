<template>
    <div class='wrapper'>
        <h2 class='d-md-none' > Search Through Our Entire Catalog</h2>
        <h1 class='d-md-flex d-none' > Search Through Our Entire Catalog </h1>
        <v-text-field
            v-model="search"
            solo
            label="Search for products"
            clearable
            class='search-bar mt-6'
            filled
          ></v-text-field>

        <product-list :products="filteredProducts"></product-list>
    </div>
</template>

<script>
import ProductList from '../components/ProductList.vue'
export default {
    data () {
        return {
            products: [],
            search: '',
            activeProducts: []
        }
    },
    mounted () {
        this.products = this.$store.getters.getProducts
    },
    components: {
        ProductList
    },
    computed: {
        filteredProducts: function() {
            return this.products.filter((product) => {
                return product.name.toLowerCase().match(this.search.toLowerCase())
            })
        }
    }
  }

</script>

<style scoped>
    .wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .search-bar {
        width: 60vw;
    }

</style>