<template>
    <div>
        <p> {{this.$route.params.email}} </p>
        <p> {{this.password}}</p>
    </div>
</template>
<script src="https://cdn.auth0.com/js/auth0/9.11/auth0.min.js"></script>
<script>
import axios from "axios"

export default {
    data () {
        return {
            password: Math.floor(100000 + Math.random() * 900000).toString(),
        }
    },

    created () {
        this.signUp()
    },

    methods: {
        async signUp () {

            let name = this.$route.params.name.replace(new RegExp("\\+","g"), ' ')
            let address = this.$route.params.address.replace(new RegExp("\\+","g"), ' ')
            let companyName = this.$route.params.companyName.replace(new RegExp("\\+","g"), ' ')

            let data = JSON.stringify({
                client_id: "GwhbWLiPc7J4OsNEd7sk39VvGjCWSjnk",
                email: this.$route.params.email,
                password: this.password.toString(),
                connection: "Username-Password-Authentication",
                "user_metadata": {name: name, address: address, companyName: companyName}
            })


            await axios.post("https://dev-xx99sf1b.us.auth0.com/dbconnections/signup", data , {
                headers:{
                    'content-type': 'application/json'
                }
            }).then(function (response){
                console.log(response)
            }).catch(function (error){
                console.log(error.response)
            })
        }
    }
}
</script>