<template> 
<div class='scroll' data-simplebar data-simplebar-auto-hide="false"> 
    <div class="scrolling-wrapper-flexbox mt-lg-4 mt-sm-6 mt-xs-10" >
      <v-icon class='icon mdi-48px d-flex d-sm-none'> mdi-gesture-swipe-left </v-icon>
      <product-scroll-card-details v-for='item in products' :key='item.id' :imageLink='item.imageURL' :productTitle="item.productName" :price="item.price" :slug='item.slug'/>
    </div>
  </div>
</template>

<script>
import ProductScrollCardDetails from '../components/ProductScrollCardDetails.vue'


export default{
    name: 'HorizontalScroll',
    components: {
        ProductScrollCardDetails
        
    },
    props: ['products'],
    data () {
      return {
        price: 0.00,
      }
    },
    created () {
      this.products.forEach(product => {
        if (!this.$auth.isAuthenticated){
          product.price = product.msrp
        }

        if (product.price == 0 || product.price == 0.00){
          product.price = 'Please Call for Price'
        }

      })
    }
}
</script>

<style scoped>
  .scrolling-wrapper-flexbox {
    height: 400px;
    width: 90vw;
    display: flex;
    flex-wrap: auto;
  }
  /* Create custom widths % for different screen sizes to maintain aspect ratio */
  .card {
    flex: 0 0 auto;
    height: 90%;
    width: 40%;
    margin-left: 40px;
  }

  .scroll {
    width: 90vw;
  }

  .scroll {
    position: relative;
  }
  .simplebar-scrollbar:before{
    background-color:red;
  }
  .icon {
    max-height:75%;
    
  }

</style>

