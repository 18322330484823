<template>
<!-- Mobile Version of Mailing List Sign up --> 
   <div class='main-wrapper'>
       <div class="mail-signup-flex mt-10 d-none d-md-flex">
     <span class='mr-2 mr-md-10 text-caption text-md-button'> Sign Up to get Updates</span>
     <span> <v-text-field
              label="Enter Your Email"
              single-line
              v-model='email'
              class='mr-10 email-input-field'
            ></v-text-field> </span>
            <span> <v-btn
                color="black"
                dark
                elevation="4"
                outlined
                large
                class='d-none d-md-flex'
              >
                JOIN!
              </v-btn>
              
              <v-btn
                color="black"
                dark
                elevation="4"
                outlined
                small
                class='d-flex d-md-none'
              >
                JOIN!
              </v-btn>
              </span>
  </div>


  <!-- Mobile Version of Mailing List Sign up --> 
  <div class="mail-signup-mobile-flex-main mt-10 d-flex d-md-none">
     <span class='ml-6 mr-md-10 text-buttontext-md-button mr-sm-10'> Sign Up to get Updates</span>
      <div class='mail-signup-mobile-flex'> <span> <v-text-field
              label="Enter Your Email"
              single-line
              v-model='email'
              class='mr-3'
            ></v-text-field> </span>
            <span> 
              <v-btn
                color="black"
                dark
                elevation="4"
                outlined
                small
                class='d-flex d-md-none mr-4'
              >
                JOIN!
              </v-btn>
              </span></div>
  </div>
   </div>

</template>

<script> 
    export default {
        name: 'MailingList',
    data () {
        return {
            email: '',
        }
    }
    }

</script>

<style> 

    .main-wrapper {
        width: 100vw;
        display: flex;
        justify-content: center;
    }
    .mail-signup-flex {
        width: 90%;
        height: 10vh;
        display: flex;
        align-items: center;
        justify-content: center;
        border: solid 2px black;
  }
.mail-signup-mobile-flex-main {
    width: 90%;
    height: 125px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: solid 2px black;
  }
  .mail-signup-mobile-flex {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .email-input-field {
    width: 300px;
  }
</style>