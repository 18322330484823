<template>
    <div class='d-flex justify-center align-center outer-wrapper'>
        <div class='main-wrapper'>
            <div class='category-selector'> 
               
            <category-horizontal-scroll class='d-none d-sm-flex mb-4' @change-category='changeCategory' :room='room'></category-horizontal-scroll>
            <a href='#scroll' class='category-link'>
                <category-grid class='d-sm-none mb-10' @change-category='changeCategory' :room='room' > </category-grid>
            </a>

            </div>
            <product-list :products="activeProducts" class='mb-8' id='scroll'></product-list>
            <SiteFooter> </SiteFooter>
        </div>    
    </div>    
</template>

<script> 
import store from '../store'
import CategoryHorizontalScroll from '../components/CategoryHorizontalScroll.vue'
import CategoryGrid from '../components/CategoryGrid.vue'
import ProductList from '../components/ProductList.vue'
import SiteFooter from '../components/SiteFooter.vue'
export default {
    components: {
        CategoryHorizontalScroll,
        CategoryGrid,
        ProductList,
        SiteFooter
    },
        data () {
            return {
                products: [],
                sortedProducts: [],
                activeProducts: ['test'],
                room: 'Bedroom',
                activeCategory: 'Vanity',
            }
        },

         async created () {
            window.scrollTo(0,0)
            this.products = this.$store.getters.getProducts    
            
        },
        async mounted(){
            await this.sleep(350)
            await this.SortByRoom(this.room) 
            if(this.$route.params.slug.trim() !== "all"){
                this.changeCategory(this.$route.params.slug.trim())
            }
            else {
                this.activeProducts = this.sortedProducts
            }

        },

        methods: { 
            changeCategory(e){
                this.activeProducts = this.sortedProducts.filter( p => p.category.trim() === e.trim())
            },
            async SortByRoom(room) {
                var array = this.products.filter( (p) => p.room === room)
                //array = this.ShuffleProducts(array)
                 array = array.sort()
                this.sortedProducts = array
            },

            sleep(ms){
                return new Promise(resolve => setTimeout(resolve, ms))
            },
            ShuffleProducts(products){
               for(var i = products.length - 1; i > 0; i--) {
                   var j = Math.floor(Math.random() * (i + 1))
                   var temp = products[i]
                   products[i] = products[j]
                   products[j] = temp
               }
               return products
            }
        }
        
    
}

</script>

<style scoped>
    body {
        scroll-behavior: smooth;
    }
    .main-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        scroll-behavior: smooth;
    }

    .category-selector {
            width: 90vw;
        }
    .category-link {
        text-decoration: none;
    }

</style>